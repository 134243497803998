import React, { useEffect } from "react";
import styled from "styled-components";
import { mobileSize } from "../../../util/variables";
import theme from "../../../util/theme";
import { ThankYou } from "../../../components/PageSegments/subscription/ThankYou";
import { BasicLayout } from "../../../layouts/BasicLayout";
import { AppLogo } from "../../../components/ui";
import { subscription_success } from "../../../services/mixpanel/mixpanel";
import { triggerCustomEvent } from "../../../services/google-tag-manager";
import { GTM_CUSTOM_EVENT } from "../../../util/types";
import { sendFBConversionEvent } from "../../../services/pixel";

export default (data: any) => {
  const { search } = data.location;
  const urlParams = new URLSearchParams(search);
  const subTypeParam = urlParams.get("subscription_type") ?? "yearly";
  const sessionParam = urlParams.get("session_id");
  const uidParam = urlParams.get("uid");

  useEffect(() => {
    localStorage.clear();
    if (sessionParam) {
      if (uidParam) {
        sendFBConversionEvent({
          value: 69.99,
          uid: uidParam,
          currency: "USD",
        });
      }

      triggerCustomEvent(GTM_CUSTOM_EVENT.PURCHASE);
    }

    subscription_success({ subscription_type: subTypeParam });
  }, [sessionParam, subTypeParam, uidParam]);

  return (
    <BasicLayout noIndex>
      <FlexContainer>
        <LogoContainer>
          <AppLogo size="medium" />
        </LogoContainer>
        <ThankYou />
      </FlexContainer>
    </BasicLayout>
  );
};

const FlexContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  background: ${theme.WHITE_COLOR};

  @media ${mobileSize} {
    justify-content: flex-start;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 100px;
  top: 80px;
  display: block;
  @media ${mobileSize} {
    position: static;
    margin: 48px 0 36px 0;
  }
`;
