import React, { useEffect } from "react";
import styled from "styled-components";
import theme from "../../../util/theme";
import { AppText, FlexDiv } from "../../ui";
import { mobileSize } from "../../../util/variables";
import { DownloadBadges } from "../../ui/DownloadBadges";
import Checkmark from "../../../content/img/icon/checkmark.png";

export const ThankYou = () => {
  useEffect(() => {
    localStorage.removeItem("flow");
  }, []);

  return (
    <div style={{ padding: 16 }}>
      <Title>You’ve joined Imprint— now download the app! </Title>
      <Container>
        <Body>
          <GetStarted>
            <FlexDiv gap={8} align="center">
              <img src={Checkmark} alt="✅" width={24} height={24} />
              <BodyText>
                <s>Log into your account</s>
              </BodyText>
            </FlexDiv>
            <FlexDiv gap={8} align="center">
              <img src={Checkmark} alt="✅" width={24} height={24} />
              <BodyText>
                <s>Start your 7-day free trial</s>
              </BodyText>
            </FlexDiv>
            <FlexDiv gap={8} align="center">
              <UncheckedBox />
              <BodyText style={{ fontWeight: "500" }}>
                Download the Imprint app and start learning
              </BodyText>
            </FlexDiv>
          </GetStarted>
          <DownloadContainer>
            <DownloadBadges mobileStack />
          </DownloadContainer>
          <EmailText>
            Questions? Email us at{" "}
            <a href="mailto:info@imprintapp.com" style={{ color: theme.BLACK_COLOR }}>
              info@imprintapp.com
            </a>
          </EmailText>
        </Body>
      </Container>
    </div>
  );
};

const Title = styled.h1`
  margin-bottom: 24px;
  font-size: 35px;
  font-weight: 600;
  color: ${theme.BLACK_COLOR};
  text-align: center;
  @media ${mobileSize} {
    font-size: 24px;
    font-weight: 700;
  }
`;

const Container = styled.div`
  max-width: 1000px;
  width: 90vw;
  padding: 80px 40px;
  box-sizing: border-box;
  background-color: #fafafa;
  border-radius: 16px;
  box-shadow: 0px 2px 8px 0px rgba(164, 164, 164, 0.25);

  @media ${mobileSize} {
    padding: 40px 0px;
    margin: 0 auto;
  }
`;

const Body = styled.div`
  max-width: 500px;
  margin: 0 auto;
`;

const GetStarted = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 56px;

  @media ${mobileSize} {
    padding: 0 48px;
  }
`;

const DownloadContainer = styled.div`
  margin-top: 51px;
  margin-bottom: 49px;

  @media ${mobileSize} {
    margin-top: 46px;
    margin-bottom: 43px;
  }
`;

const BodyText = styled(AppText as any)`
  font-size: 18px;
  line-height: 24px;
  color: ${theme.BLACK_COLOR};
  margin: 0;
  a {
    text-decoration: underline;
    color: ${theme.PRIMARY_COLOR};
  }
`;

const EmailText = styled(AppText as any)`
  font-size: 16px;
  line-height: 24px;
  color: ${theme.BLACK_COLOR};
  margin: 0;
  a {
    text-decoration: underline;
  }
  @media ${mobileSize} {
    text-align: center;
    margin: 0 16px;
  }
`;

const UncheckedBox = styled.div`
  width: 24px;
  height: 24px;
  aspect-ratio: 1/1;
  border: 3px solid ${theme.PRIMARY_COLOR};
  border-radius: 4px;
`;
